import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const About = () => {
  return (
    <div class='wrapper bios'>
      <Helmet>
        <title>About | BIOS</title>
      </Helmet>

      <h1>
        <Link to='/discography'>
          Basic Input Output System - Transient POST Embodiment (2019)
        </Link>
      </h1>

      <div class='container about'>
        <p>Metal, played through Pigtronix Mothership.</p>

        <p>
          <Link to='/'>Back</Link>
        </p>
      </div>
    </div>
  );
};

export default About;
