import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <h1>
      <Link to='/discography'>
        Basic Input Output System - Transient POST Embodiment (2019)
      </Link>
    </h1>
  );
};

export default Header;
