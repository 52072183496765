import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Discography = () => {
  return (
    <div className='wrapper'>
      <Helmet>
        <title>Album | BIOS</title>
      </Helmet>
      <h1>
        <Link to='/discography'>
          Basic Input Output System - Transient POST Embodiment (2019)
        </Link>
      </h1>

      <div className='container discography'>
        <div className='disc-box'>
          <h1>Transient POST Embodiment</h1>

          <p>Tracklist:</p>

          <ul className='tracklist'>
            <li>Advanced System Exasperation</li>
            <li>Millenium CPU Ration Setting Concatenation</li>
            <li>Northbridge-Southbridge Pleonasm</li>
            <li>Bus Speed Transfiguration</li>
            <li>Loading Integrated Peripherial Oblivions</li>
            <li>Desummon Hardware Prefetcher</li>
            <li>Boot-Time Diagnostic Excerpt</li>
          </ul>

          <p>Line up:</p>

          <ul className='lineup'>
            <li>Input - guitar</li>
            <li>Output - bass</li>
          </ul>

          <p>recorded 2018, released feb 2019.</p>

          <p>Streaming & download:</p>

          <ul className='streaming'>
            <li>
              <a
                href='https://basicinputoutputsystem.bandcamp.com'
                target='_blank'
                rel='noreferrer'>
                Bandcamp
              </a>
            </li>
            <li>
              <a
                href='https://soundcloud.com/basicinputoutputsystem'
                target='_blank'
                rel='noreferrer'>
                Soundcloud
              </a>
            </li>
            <li>
              <a
                href='https://youtu.be/nyk0tqYAdIU'
                target='_blank'
                rel='noreferrer'>
                Youtube
              </a>
            </li>
            <li>
              <a href='download/bios2019.7z'>Download</a>
            </li>
          </ul>
        </div>
        <div className='album-cover-box'>
          <img
            className='tpe'
            src={`${process.env.PUBLIC_URL}/images/Basic-Input-Output-System-Transient-POST-Embodiment-2019-cover.png`}
            alt='Album cover'
          />
        </div>
        <p class='back'>
          <Link to='/'>Back</Link>
        </p>
      </div>
    </div>
  );
};

export default Discography;
