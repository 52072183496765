import { Link } from 'react-router-dom';

const Tour = () => {
  return (
    <div class='wrapper bios'>
      <h1>
        <Link to="/discography">Basic Input Output System - Transient POST Embodiment (2019)</Link>
      </h1>

      <div class='container tour'>
        <p>TBA.</p>
        <p><Link to="/">Back</Link></p>
      </div>
    </div>
  );
};

export default Tour;
