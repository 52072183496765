import Header from './Header';
//import Discography from "./Discography";
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

const Homepage = () => {
  return (
    <div className='wrapper'>
      <Helmet>
        <title>Tech Synth Metal | BIOS</title>
      </Helmet>
      <Header />
      <div className='container'>
        <div className='left'>
          <ul>
            <li>
              <Link to='#'>Hail Navigated DEL Traveler</Link>
            </li>
            <li>
              <Link to='/discography'>Album Info & CMOS Features</Link>
            </li>
            <li>
              <Link to='/about'>About & SATA Option Settings</Link>
            </li>
            <li>
              <Link to='/tour'>Tour Dates, USB Support</Link>
            </li>
            <li>
              <Link to='/contact'>Load Contact Setup</Link>
            </li>
            <li>
              <Link to='#'>Additional Media & Downloads</Link>
            </li>
          </ul>
        </div>

        <div className='right'>
          <ul>
            <li>
              <Link to='#'>Album streaming options:</Link>
            </li>
            <li>
              <a
                href='https://basicinputoutputsystem.bandcamp.com'
                target='_blank' rel="noreferrer">
                Bandcamp platform
              </a>
            </li>
            <li>
              <a
                href='https://soundcloud.com/basicinputoutputsystem'
                target='_blank' rel="noreferrer">
                Soundcloud platform
              </a>
            </li>
            <li>
              <a href='https://youtu.be/nyk0tqYAdIU' target='_blank' rel="noreferrer">
                Youtube platform
              </a>
            </li>
            <li>
              <Link to='#'>Save & Exit Setup</Link>
            </li>
            <li>
              <Link to='#'>Exit Without Saving</Link>
            </li>
          </ul>
        </div>

        <div className='info'>
          <div className='info-l'>
            <ul>
              <li>Esc : Quit</li>
              <li>F10 : Save & Exit Setup</li>
            </ul>
          </div>

          <div className='info-r'>
            <i className='fa fa-long-arrow-up' aria-hidden='true'></i>
            <i className='fa fa-long-arrow-down' aria-hidden='true'></i>
            <i className='fa fa-long-arrow-right' aria-hidden='true'></i>
            <i className='fa fa-long-arrow-left' aria-hidden='true'></i>
            <p>: Select item</p>
          </div>
        </div>

        <div className='footer'>
          <p id='footerText'></p>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
