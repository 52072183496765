import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Contact = () => {
  return (
    <div class='wrapper bios'>
      <Helmet>
        <title>Contact | BIOS</title>
      </Helmet>

      <h1>
        <Link to='/discography'>
          Basic Input Output System - Transient POST Embodiment (2019)
        </Link>
      </h1>

      <div class='container contact'>
        <p class='email'>
          <a href='mailto:basicinputoutputsystemproject@gmail.com'>
            basicinputoutputsystemproject@gmail.com
          </a>
        </p>
        <p>
          <a
            href='https://www.facebook.com/basicinputoutputsystemproject'
            target='_blank'
            rel='noreferrer'>
            Facebook - link
          </a>
        </p>
        <p>
          <a
            href='https://basicinputoutputsystem.bandcamp.com'
            target='_blank'
            rel='noreferrer'>
            Bandcamp - link
          </a>
        </p>
        <p>
          <a
            href='https://soundcloud.com/basicinputoutputsystem'
            target='_blank'
            rel='noreferrer'>
            Soundcloud - link
          </a>
        </p>

        <p>
          <Link to='/'>Back</Link>
        </p>
      </div>
    </div>
  );
};

export default Contact;
